<template>
    <div class="text-left">
      <h3 class="f-bold cl-primary mb-4">Update Password</h3>
      <form class="mt-4" @submit.prevent="updatePassword">
        <div class="row">
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
            <input
              type="text"
              class="form-control b-radius px-4"
              id="password"
              placeholder="New Password"
              v-model="form.password"
              required
            />
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
            <input
              type="text"
              class="form-control b-radius px-4"
              id="password"
              placeholder="Confirmation New Password"
              v-model="form.password_confirmation"
              required
            />
          </div>
        </div>
        <div class="d-flex justify-content-center mt-4" v-if="!loading">
          <button type="submit" class="btn btn-success px-5">Save</button>
        </div>
        <div class="d-flex justify-content-center mt-4" v-if="loading">
          <loader-component></loader-component>
        </div>
      </form>
    </div>
  </template>
  <script>
  import Vue from "vue";
  export default {
    name: "MyPasswordForm",
    data() {
      return {
        loading: false,
        form: {
          password: "",
          password_confirmation: "",
        },
      };
    },
    computed: {
      userLogin: function () {
        return this.$store.getters.getProfileCustomers;
      },
    },
    mounted() {
    //   this.setForm();
    },
    methods: {
    //   setForm() {
    //     this.form.name = this.userLogin.name;
    //     this.form.email = this.userLogin.email;
    //     this.form.telephone = this.userLogin.telephone;
    //     this.form.gender = this.userLogin.gender;
    //     this.form.address = this.userLogin.address;
    //     this.form.username = this.userLogin.username;
    //   },
  
      updatePassword() {
        this.loading = true;
        const endpoint = "change-password";
        this.$api.post(endpoint, this.form, (status, data, message) => {
          if (status === 200) {
            this.loading = false;
            console.log(data);
            Vue.swal(
              "Success",
              "Password status has been successfully updated",
              "success"
            ).then(() => {
              this.$router.push("/customers/my-profile");
            });
          } else {
            Vue.swal(
              "Error",
              "Password and confirm password not match!",
              "error"
            )
            this.loading = false;
            console.log(message);
          }
        });
      },
    },
  };
  </script>
  